import { Routes, Route } from "react-router-dom";
import "./App.css";
import AboutUs from "./components/AboutUs";
import Header from "./components/Header";
import Hero from "./components/Hero";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import CallToAction from "./components/CallToAction";
import Footer from "./components/Footer";
import About from "./pages/About";
import ServicesPage from "./pages/Services";
import Projects from "./pages/Projects";
import Contact from "./pages/Contact";

const MainLayout = () => (
  <>
    <Hero />
    <AboutUs />
    <Services />
    {/* <Testimonials /> */}
    <CallToAction />
  </>
);

const ErrorBoundary = ({ children }) => {
  try {
    return children;
  } catch (error) {
    console.error("Error rendering component:", error);
    return <div>Something went wrong.</div>; // basic error handling UI
  }
};

function App() {
  return (
    <div className="App">
      <Header /> {/* Render Header for all routes */}
      <ErrorBoundary>
        <Routes>
          {/* Define the main layout for the home page */}
          <Route path="/" element={<MainLayout />} />
          {/* Separate About page route */}
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </ErrorBoundary>
      <Footer /> {/* Render Footer for all routes */}
    </div>
  );
}

export default App;
