import React from "react";
import PagesHero from "../components/PagesHero";
import { Helmet } from "react-helmet";
import CallToAction from "../components/CallToAction";

// AboutUs component representing the About Us page
const AboutUs = () => {
  // Error handling mechanism
  const hasError = false; // This can be modified to include actual error handling logic

  if (hasError) {
    return (
      <div className="text-red-500">
        An error occurred while loading the page.
      </div>
    );
  }

  // Core values of the organization
  const coreValues = [
    {
      title: "Dialogue",
      description:
        "We believe in open conversations that foster understanding, collaboration, and progress.",
    },
    {
      title: "Transformation",
      description:
        "Our goal is to catalyze transformation in governance and society by proposing innovative and practical solutions.",
    },
    {
      title: "Impact",
      description:
        "We are dedicated to creating tangible impacts through our research and policy work.",
    },
    {
      title: "Empowerment",
      description:
        "Empowering individuals and communities to participate in governance is at the heart of what we do.",
    },
  ];

  // Team members associated with the organization
  const teamMembers = [
    {
      img: "/team-member1.jpg",
      alt: "John Doe, Founder & CEO",
      name: "John Doe",
      position: "Founder & CEO",
    },
    {
      img: "/team-member2.jpg",
      alt: "Jane Smith, Head of Operations",
      name: "Jane Smith",
      position: "Head of Operations",
    },
    {
      img: "/team-member3.jpg",
      alt: "Ali Khan, Community Outreach Manager",
      name: "Ali Khan",
      position: "Community Outreach Manager",
    },
  ];

  return (
    <>
      {/* SEO metadata for the About Us page */}
      <Helmet>
        <title>About Us - Greater Sylhet Community UK</title>
        <meta
          name="description"
          content="Learn more about the mission, vision, and values of the Greater Sylhet Community UK, empowering the Sylheti community through cultural awareness, inclusivity, and growth."
        />
        <meta
          name="keywords"
          content="Sylheti, UK community, cultural awareness, inclusivity, education, mission, vision"
        />
        <meta
          property="og:title"
          content="About Us - Greater Sylhet Community UK"
        />
        <meta
          property="og:description"
          content="Discover how Greater Sylhet Community UK empowers the Sylheti community through education and cultural initiatives."
        />
        <meta property="og:image" content="/assets/herobg.webp" />
        <meta
          property="og:url"
          content="https://www.greatersylhetcommunity.org/about"
        />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      {/* Page hero section with title */}
      <PagesHero
        title="About Us"
        imageUrl="/assets/7.png"
        imagePosition={"top"}
      />

      {/* Section describing the organization's mission and vision */}
      <section className="py-12 bg-gray-100 text-gray-800">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <h2 className="text-3xl font-bold text-accent mb-6">
                Our Mission
              </h2>
              <p className="text-lg">
                Our mission is to empower the Sylheti community by fostering
                growth, cultural awareness, and inclusivity. We are committed to
                promoting education and creating opportunities for everyone to
                thrive and contribute positively.
              </p>
            </div>
            <div>
              <h2 className="text-3xl font-bold text-accent mb-6">
                Our Vision
              </h2>
              <p className="text-lg">
                We envision a future where the Sylheti community is strong,
                united, and playing an active role in shaping the future of our
                society through cultural initiatives and outreach.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section highlighting the core values of the organization */}
      <section className="py-12 bg-white">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
          <h2 className="text-4xl font-semibold text-center mb-8">
            Our Core Values
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {coreValues.map((value, index) => (
              <div
                key={index}
                className="bg-gray-100 p-6 rounded-lg shadow-md text-center"
              >
                <h3 className="text-2xl font-semibold mb-2">{value.title}</h3>
                <p>{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Section introducing the team members within the organization */}
      {/* <section id="team" className="py-12 bg-gray-100">
        <div className="container mx-auto">
          <h2 className="text-3xl font-semibold text-center mb-8">Meet Our Team</h2>
          <div className="flex flex-wrap justify-center gap-8">
            {teamMembers.map((member, index) => (
              <div key={index} className="text-center">
                <img src={member.img} alt={member.alt} className="rounded-full w-32 h-32 object-cover mb-4 mx-auto" />
                <h3 className="text-xl font-semibold">{member.name}</h3>
                <p className="text-sm">{member.position}</p>
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* Section telling the organization's story */}
      <div className="bg-white p-8 rounded-lg shadow-lg mb-12">
        <h2 className="text-2xl font-semibold mb-6 text-center">Our Story</h2>
        <p className="text-lg leading-relaxed">
          Our journey began with a small group of individuals passionate about
          supporting the Sylheti community. Over the years, we have grown into a
          robust organization, providing essential services, educational
          programs, and cultural initiatives. Through perseverance and a shared
          vision, we continue to grow and adapt to the evolving needs of the
          community.
        </p>
      </div>

      {/* Call to action for contacting the organization */}
      <CallToAction />
    </>
  );
};

// Exporting the AboutUs component for use in other parts of the application
export default AboutUs;
