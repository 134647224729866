import { useState, useEffect } from "react";
import { FORM_OFFLINE, FORM_URL } from "../constant";

function Hero() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // State to track the current image index
  const [loadedImage, setLoadedImage] = useState(""); // State to track the loaded image URL

  useEffect(() => {
    const images = ["/assets/herobg.webp"]; // Move the images array inside useEffect

    const loadNextImage = () => {
      const nextIndex = (currentImageIndex + 1) % images.length;
      setLoadedImage(images[nextIndex]);
      setCurrentImageIndex(nextIndex);
    };

    // Load the initial image
    setLoadedImage(images[0]);

    const interval = setInterval(loadNextImage, 3000); // Change image every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [currentImageIndex]); // Remove images from dependency array

  return (
    <section
      className="relative bg-cover bg-center min-h-[100vh] sm:h-[60vh] text-neutralWhite flex flex-col justify-center items-center"
      style={{
        backgroundImage: `url(${loadedImage})`,
        backgroundPosition: 'center',
      }}
      role="banner"
      aria-label="Hero Section with background images cycling every 3 seconds"
    >
      {/* Full Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>

      {/* Content (set higher z-index) */}
      <div className="relative z-20 text-center px-4 sm:px-6 md:px-12 max-w-[90%] sm:max-w-[80%] mx-auto">
        <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mb-3 sm:mb-4">
          Empowering the Sylheti Community in the UK
        </h1>
        <p className="text-base sm:text-lg md:text-xl mb-6 sm:mb-8">
          Join us in supporting our mission to uplift and support the Sylheti
          community.
        </p>
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-2 justify-center items-center">
          <a
            href={FORM_URL}
            target="_blank"
            rel="noopener noreferrer" 
            className="bg-accent text-darkBlue py-3 px-8 rounded-full text-base sm:text-lg font-bold w-full sm:w-auto hover:bg-opacity-90 transition-all duration-300"
            aria-label="Get involved with the Sylheti community"
          >
            Online Membership
          </a>
          {/* Download Membership Form Button */}
          <a
            href={FORM_OFFLINE}
            download
            className="bg-accent text-darkBlue py-3 px-8 rounded-full text-base sm:text-lg font-bold w-full sm:w-auto hover:bg-opacity-90 transition-all duration-300"
            aria-label="Download membership form"
          >
            Printable Membership
          </a>
        </div>
      </div>
    </section>
  );
}

export default Hero;
