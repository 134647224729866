import React from "react";
import { FORM_URL } from "../constant";

// ErrorBoundary is a React component that catches JavaScript errors in its child component tree.
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false }; // Initializes state to track if an error has occurred.
  }

  // Updates state to indicate an error has occurred when an error is caught.
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  // Logs error information when an error occurs.
  componentDidCatch(error, errorInfo) {
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  // Renders fallback UI when an error has occurred; otherwise, renders child components.
  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

// CallToAction is a functional component that renders a section encouraging user involvement.
function CallToAction() {
  return (
    <section
      className="bg-primary text-neutralWhite py-16 px-6 md:px-12 text-center"
      aria-label="Get Involved Call to Action"
    >
      <div className="container mx-auto">
        <h2 className="text-2xl md:text-4xl font-bold mb-6">
          Get Involved Today!
        </h2>
        <p className="text-base md:text-xl mb-6 md:mb-8">
          Join us in making a difference within the Sylheti community. Whether
          through volunteering or donations, your support helps us continue our
          mission.
        </p>

        {/* Buttons */}
        <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4">
          {/* Online Membership Button */}
          <a
            href={FORM_URL}
            target="_blank"
            rel="noopener noreferrer"
            className="bg-accent text-darkBlue py-3 px-8 rounded-full text-lg font-bold"
            aria-label="Learn how to get involved with the Sylheti community"
          >
            Online Membership
          </a>

          {/* Printable Membership Form Button */}
          <a
            href="assets/GSCUK_Membership_Form.pdf"
            download
            className="bg-accent text-darkBlue py-3 px-8 rounded-full text-lg font-bold"
            aria-label="Download membership form"
          >
            Printable Membership
          </a>
        </div>
      </div>
    </section>
  );
}

// App component wraps the CallToAction component with ErrorBoundary to handle errors.
export default function App() {
  return (
    <ErrorBoundary>
      <CallToAction />
    </ErrorBoundary>
  );
}
