import React from "react";
import PagesHero from "../components/PagesHero";
import { Helmet } from "react-helmet";
import CallToAction from "../components/CallToAction";
 
// Services component that renders the services offered by Greater Sylhet Community UK
const Services = () => {
  // Memoized array of services to enhance performance by preventing recalculation on every render
  const services = React.useMemo(() => [
    {
      title: "Educational Support",
      description: "Providing resources and guidance to the Sylheti community, ensuring access to quality education for everyone.",
      icon: "📚",
    },
    {
      title: "Cultural Events",
      description: "Hosting cultural events to celebrate and preserve the rich Sylheti heritage, promoting unity within the community.",
      icon: "🎉",
    },
    {
      title: "Community Projects",
      description: "Engaging in various community development projects aimed at improving the lives of Sylheti individuals in the UK.",
      icon: "🏘️",
    },
    {
      title: "Advocacy and Awareness",
      description: "Advocating for the rights and representation of the Sylheti community, and raising awareness of important issues.",
      icon: "📢",
    },
  ], []);
 
  return (
    <>
      <Helmet>
        <title>Our Services - Greater Sylhet Community UK</title>
        <meta
          name="description"
          content="Discover the various services offered by Greater Sylhet Community UK, including educational support, cultural events, community projects, and advocacy for the Sylheti community."
        />
        <meta
          name="keywords"
          content="Sylheti services, community support, cultural events, advocacy, educational support"
        />
        <meta property="og:title" content="Our Services - Greater Sylhet Community UK" />
        <meta
          property="og:description"
          content="Greater Sylhet Community UK offers a range of services aimed at empowering the Sylheti community in the UK."
        />
        <meta property="og:image" content="/assets/herobg.webp" />
        <meta property="og:url" content="https://www.greatersylhetcommunity.org/services" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
 
      {/* Hero Section */}
      <PagesHero title="Our Services" imageUrl="/assets/8.png" imagePosition={"center center"} />
 
      {/* Services Section */}
      <section className="py-12 bg-white">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
          <h2 className="text-4xl font-semibold text-center mb-8">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {services.length ? (
              services.map((service, index) => (
                <div
                  key={index}
                  className="bg-gray-100 p-6 rounded-lg shadow-md text-center"
                >
                  <div className="text-5xl mb-4">{service.icon}</div>
                  <h3 className="text-2xl font-semibold mb-2">{service.title}</h3>
                  <p className="text-lg leading-relaxed">{service.description}</p>
                </div>
              ))
            ) : (
              <p className="text-center text-lg text-red-600">No services available at the moment.</p>
            )}
          </div>
        </div>
      </section>
 
      {/* Call to Action */}
     <CallToAction />
    </>
  );
};
 
// Exporting the Services component for use in other parts of the application
export default Services;