import React from "react";
// import cover from "../assets/herobg.webp";

// AboutUs component that displays information about the Greater Sylhet Community UK
function AboutUs() {
  // Error handling for image loading
  const handleImageError = (e) => {
    e.target.onerror = null; // Prevents looping
    e.target.src = "../assets/defaultImage.webp"; // Fallback image
  };

  return (
    <section
      className="py-16 px-6 md:px-12 bg-neutralGray text-darkBlue"
      role="contentinfo"
    >
      <div className="container mx-auto">
        <h2 className="text-3xl md:text-4xl font-bold text-center mb-8">
          About Greater Sylhet Community UK
        </h2>
        <article className="flex flex-col md:flex-row items-center">
          {/* Image */}
          <div className="md:w-1/2 mb-8 md:mb-0">
            <img
              src="../assets/herobg.webp"
              alt="Greater Sylhet Community UK - Cultural Event"
              className="w-full rounded-lg shadow-md"
              onError={handleImageError} // Adding error handling
              loading="lazy"
            />
          </div>
          {/* Content */}
          <div className="md:w-1/2 md:pl-8">
            <p className="text-lg mb-4">
              Greater Sylhet Community UK is dedicated to empowering the Sylheti
              community through educational and cultural initiatives.
            </p>
            <p className="text-lg">
              Our mission is to preserve Sylheti heritage while offering
              essential services to help the community thrive and positively
              impact society.
            </p>
            <a
              href="/about"
              className="mt-6 inline-block bg-primary text-neutralWhite py-2 px-4 rounded-full"
              aria-label="Learn more about the Greater Sylhet Community UK"
            >
              Learn More
            </a>
          </div>
        </article>
      </div>
    </section>
  );
}

export default AboutUs;
