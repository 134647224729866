import React from "react";
import { FaFacebook, FaInstagram } from "react-icons/fa";
import { BsTwitterX } from "react-icons/bs";

// Footer component that displays site branding, quick links, contact information, and social media links.
function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="bg-neutralBlack text-neutralWhite py-8"
      role="contentinfo"
      aria-label="Footer"
    >
      <div className="container mx-auto text-center md:text-left">
        <div className="flex flex-col md:flex-row justify-between items-center md:items-start">
          {/* Logo and Description */}
          <div className="mb-6 md:mb-0">
            <a href="/">
              <img
                src="assets/logo.png"
                alt="Greater Sylhet Community UK logo"
                className="h-16 mb-4 mx-auto md:mx-0"
                onError={(e) => {
                  e.target.onerror = null; // Prevents looping on errors
                  e.target.src = "path/to/default/logo.png"; // Provide path to a default logo
                }}
              />
            </a>
            <h3 className="text-2xl font-bold mb-2">
              Greater Sylhet Community UK
            </h3>
            <p className="text-sm">
              Dedicated to supporting and empowering the Sylheti community in
              the UK.
            </p>
          </div>

          {/* Quick Links */}
          <div className="mb-6 md:mb-0">
            <h4 className="text-xl font-semibold mb-3">Quick Links</h4>
            <ul className="text-sm space-y-2">
              {["/about", "/services", "/projects", "/contact"].map(
                (link, index) => (
                  <li
                    key={index}
                    className="transform transition-transform duration-300 hover:translate-x-2"
                  >
                    <a
                      href={link}
                      className="hover:underline hover:text-accent"
                    >
                      {link.replace("/", "").replace(/-/, " ").toUpperCase()}
                    </a>
                  </li>
                )
              )}
            </ul>
          </div>

          {/* Contact Info */}
          <div className="mb-6 md:mb-0">
            <h4 className="text-xl font-semibold mb-3">Contact Us</h4>
            <p className="text-sm">
              {/* 123 Sylhet Road, London, UK
              <br /> */}
              Email:{" "}
              <a
                href="mailto:info@greater-sylhet.org"
                className="hover:underline hover:text-accent transition duration-300 transform hover:translate-x-2"
              >
                info@greatersylhetcommunity.org.uk
              </a>
              {/* <br />
              Phone: +44 1234 567890 */}
            </p>
          </div>

          {/* Social Media */}
          <div className="mb-6 md:mb-0">
            <h4 className="text-xl font-semibold mb-3">Follow Us</h4>
            <div className="flex justify-center md:justify-start space-x-4">
              {[
                {
                  href: "https://facebook.com",
                  icon: <FaFacebook className="w-6 h-6" />,
                },
                {
                  href: "https://twitter.com",
                  icon: <BsTwitterX className="w-6 h-6" />,
                },
                {
                  href: "https://instagram.com",
                  icon: <FaInstagram className="w-6 h-6" />,
                },
              ].map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  aria-label={`Visit us on ${social.href.split(".")[1]}`}
                  className="hover:text-accent transition duration-300 transform hover:translate-x-2"
                >
                  {social.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="mt-8 border-t border-neutralWhite pt-4 text-sm text-center">
          © {currentYear} Greater Sylhet Community UK. All rights reserved.
        </div>
      </div>
    </footer>
  );
}

export default Footer;
