import React from "react";

function PagesHero({ title, imageUrl, imagePosition }) {
  return (
    <section
      className="relative bg-cover bg-center h-[50vh] text-neutralWhite flex flex-col justify-center items-center"
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundPosition: imagePosition // Use imageUrl prop
      }}
      role="banner"
      aria-label={`${title} - Hero Section`}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>
      <div className="relative z-20 text-center px-6 md:px-12">
        <h1 className="text-4xl md:text-6xl font-bold mb-4">{title}</h1>
      </div>
    </section>
  );
}

export default PagesHero;
