import React from "react";
import PagesHero from "../components/PagesHero";
import { Helmet } from "react-helmet";
import CallToAction from "../components/CallToAction";
 
// Array of project objects containing project details such as title, description, image, and status
const projects = [
  {
    title: "Sylheti Cultural Festival",
    description: "A week-long celebration of Sylheti culture, featuring performances, exhibitions, and workshops to promote heritage and unity.",
    image: "/assets/2.png",
    status: "Ongoing",
  },
  {
    title: "Education for All",
    description: "An initiative focused on providing educational support to children and young adults in the Sylheti community, ensuring equal access to resources.",
    image: "/assets/3.png",
    status: "Completed",
  },
  {
    title: "Health Awareness Campaign",
    description: "A community-wide campaign aimed at raising awareness on healthcare issues, offering free health check-ups and consultations.",
    image: "/assets/4.png",
    status: "Ongoing",
  },
  {
    title: "Sylheti Community Center",
    description: "Building a new community center to serve as a hub for cultural events, social services, and community meetings.",
    image: "/assets/5.png",
    status: "In Progress",
  },
];
 
// Styles for project status labels
const statusStyles = {
  Ongoing: "bg-green-200 text-green-800",
  "In Progress": "bg-yellow-200 text-yellow-800",
  Completed: "bg-gray-200 text-gray-800",
};
 
// Functional component that displays the projects page
const Projects = () => {
  return (
    <>
      <Helmet>
        <title>Our Projects - Greater Sylhet Community UK</title>
        <meta
          name="description"
          content="Explore the various community projects initiated by Greater Sylhet Community UK, including cultural festivals, education programs, and healthcare initiatives."
        />
        <meta
          name="keywords"
          content="Sylheti projects, community initiatives, cultural events, education support, health campaigns"
        />
        <meta property="og:title" content="Our Projects - Greater Sylhet Community UK" />
        <meta
          property="og:description"
          content="Greater Sylhet Community UK is working on a variety of community projects aimed at improving the lives of the Sylheti community in the UK."
        />
        <meta property="og:image" content={projects[0].image} />
        <meta property="og:url" content="https://www.greatersylhetcommunity.org/projects" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
 
      {/* Hero Section */}
      <PagesHero title="Our Projects" imageUrl="/assets/9.png" imagePosition={"cover"} />
 
      {/* Projects Section */}
      <section className="py-12 bg-white">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
          <h2 className="text-4xl font-semibold text-center mb-8">Our Projects</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-8">
            {projects.map((project, index) => (
              // Renders individual project details in a card format
              <div key={index} className="bg-gray-100 p-6 rounded-lg shadow-md">
                <img src={project.image} alt={project.title} className="rounded-lg mb-4 w-full h-48 object-cover" />
                <h3 className="text-2xl font-semibold mb-2">{project.title}</h3>
                <p className="text-lg mb-4">{project.description}</p>
                <span className={`inline-block px-3 py-1 rounded-full text-sm font-semibold ${statusStyles[project.status] || "bg-gray-200 text-gray-800"}`}>
                  {project.status}
                </span>
              </div>
            ))}
          </div>
        </div>
      </section>
 
      {/* Call to Action */}
    <CallToAction/>
    </>
  );
};
 
// Error handling mechanism
try {
  // Code that might throw an error
} catch (error) {
  console.error("Error occurred while rendering projects:", error);
}
 
export default Projects;