import React, { useRef } from "react";
import emailjs from "emailjs-com";
import PagesHero from "../components/PagesHero";
import { Helmet } from "react-helmet";

// Contact component for handling user inquiries and sending emails
const Contact = () => {
  const form = useRef();

  // Function to send email using EmailJS when the form is submitted
  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      await emailjs.sendForm(
        "YOUR_SERVICE_ID", // Replace with your EmailJS service ID
        "YOUR_TEMPLATE_ID", // Replace with your EmailJS template ID
        form.current,
        "YOUR_USER_ID" // Replace with your EmailJS user ID
      );
      alert("Message sent successfully!");
    } catch (error) {
      console.error("Email sending error:", error);
      alert("Failed to send message. Please try again.");
    }
    e.target.reset(); // Reset the form after submission
  };

  return (
    <>
      <Helmet>
        <title>Contact Us - Greater Sylhet Community UK</title>
        <meta
          name="description"
          content="Get in touch with the Greater Sylhet Community UK. We are here to answer your questions and assist with any inquiries you may have."
        />
      </Helmet>

      {/* Hero Section */}
      <PagesHero title="Contact Us" imageUrl="/assets/10.png" />

      {/* Contact Section */}
      <section className="py-12 bg-gray-50 text-gray-800">
        <div className="container mx-auto px-6 md:px-12 lg:px-20">
          <div className="flex flex-col md:flex-row md:justify-between items-center md:space-x-12">
            {/* Contact Form */}
            <div className="md:w-1/2">
              <form
                ref={form}
                onSubmit={sendEmail}
                className="bg-white p-8 rounded-lg shadow-lg"
              >
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
                  <div>
                    <label
                      htmlFor="name"
                      className="block text-lg font-medium mb-2"
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id="name"
                      className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-accent"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="phone"
                      className="block text-lg font-medium mb-2"
                    >
                      Phone No
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-accent"
                      required
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="email"
                    className="block text-lg font-medium mb-2"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-accent"
                    required
                  />
                </div>

                <div className="mb-6">
                  <label
                    htmlFor="message"
                    className="block text-lg font-medium mb-2"
                  >
                    Message
                  </label>
                  <textarea
                    name="message"
                    id="message"
                    rows="5"
                    className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-accent"
                    required
                  ></textarea>
                </div>

                <button
                  type="submit"
                  className="bg-accent text-white py-3 px-6 rounded-full w-full hover:bg-darkBlue transition duration-300"
                >
                  Send Message
                </button>
              </form>
            </div>
            {/* Contact Info */}
            <div className="mb-12 md:mb-0 md:w-1/2">
              <h2 className="text-4xl font-bold mb-6 text-darkBlue">
                We'd Love to Hear From You!
              </h2>
              <p className="text-lg mb-4">
                Whether you have questions, suggestions, or just want to say
                hello, feel free to reach out to us using the form. We’ll
                respond as soon as possible.
              </p>
              <div className="mt-8 space-y-6">
                <p>
                  <strong>Email:</strong> info@greatersylhetcommunity.org.uk
                </p>
                <p>
                  <strong>Phone:</strong> +44 1234 567890
                </p>
                {/* <p>
                  <strong>Address:</strong> 123 Sylhet Road, London, UK
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
