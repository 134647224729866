import React from "react";

// Array containing information about services offered, including title, description, and icon
const services = [
  {
    title: "Educational Support",
    description:
      "Offering guidance and resources for education to the Sylheti community.",
    icon: "📚", // Replace with actual icons/images
  },
  {
    title: "Cultural Events",
    description: "Preserving Sylheti traditions and hosting cultural events.",
    icon: "🎉",
  },
  {
    title: "Community Projects",
    description:
      "Initiatives focused on community development and empowerment.",
    icon: "🏘️",
  },
];

// Functional component that renders the Services section
function Services() {
  // Error handling: Check if services array is empty
  if (!Array.isArray(services) || services.length === 0) {
    return (
      <section className="py-16 px-6 md:px-12 bg-white text-darkBlue">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl md:text-4xl font-bold mb-8">Our Services</h2>
          <p className="text-lg">No services available at the moment.</p>
        </div>
      </section>
    );
  }

  return (
    <section
      className="py-16 px-6 md:px-12 bg-white text-darkBlue"
      role="region"
      aria-label="Our Services Section"
    >
      <div className="container mx-auto text-center">
        <h2 className="text-3xl md:text-4xl font-bold mb-8">Our Services</h2>
        <div className="flex flex-col md:flex-row justify-center space-y-6 md:space-y-0 md:space-x-6">
          {services.map((service, index) => (
            <article
              key={index}
              className="bg-neutralGray p-6 rounded-lg shadow-md w-full md:w-1/3"
              aria-labelledby={`service-title-${index}`}
            >
              <div className="text-5xl mb-4">{service.icon}</div>
              <h3
                id={`service-title-${index}`}
                className="text-2xl font-bold mb-2"
              >
                {service.title}
              </h3>
              <p className="text-lg">{service.description}</p>
            </article>
          ))}
        </div>
      </div>
    </section>
  );
}

// Exporting the Services component for use in other parts of the application
export default Services;
