import { useState } from "react";
import { Link } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { FORM_URL } from "../constant";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [getInvolvedOpen, setGetInvolvedOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
    // Close dropdowns when closing menu
    if (isOpen) {
      setDropdownOpen(false);
      setGetInvolvedOpen(false);
    }
  };

  const closeMenu = () => {
    setIsOpen(false);
    setDropdownOpen(false);
    setGetInvolvedOpen(false);
  };

  const navLinks = [
    { href: "/", label: "Home" },
    { href: "/about", label: "About Us" },
    { href: "/services", label: "Services" },
    { href: "/projects", label: "Projects" },
    // {
    //   href: "/regions-and-branches",
    //   label: "Regions and Branches",
    //   hasDropdown: true,
    // },
    { href: "/contact", label: "Contact" },
  ];

  const dropdownLinks = [
    { href: "/region1", label: "Region 1" },
    { href: "/region2", label: "Region 2" },
    { href: "/region3", label: "Region 3" },
  ];

  const getInvolvedLinks = [
    { href: FORM_URL, label: "Online Membership" },
    {
      href: "assets/GSCUK_Membership_Form.pdf",
      label: "Printable Membership",
      download: true,
    },
  ];

  return (
    <header className="bg-primary text-neutralWhite p-4">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" aria-label="Home" className="flex-shrink-0">
          <img
            src="/assets/logo.png"
            alt="Greater Sylhet Community UK Logo"
            className="h-16"
            loading="lazy"
          />
        </Link>

        <button
          onClick={toggleMenu}
          className="md:hidden ml-4"
          aria-label="Toggle Mobile Menu"
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>

        <nav
          aria-label="Primary Navigation"
          className="hidden md:flex justify-center flex-grow space-x-6 font-semibold"
        >
          {navLinks.map((link) => (
            <div key={link.href} className="relative">
              {link.hasDropdown ? (
                <div>
                  <Link
                    to={link.href}
                    className="hover:text-accent flex items-center"
                    onMouseEnter={() => setDropdownOpen(true)}
                    onMouseLeave={() => setDropdownOpen(false)}
                  >
                    {link.label}
                    <FaChevronDown className="ml-1" />
                  </Link>
                  {dropdownOpen && (
                    <div className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg text-darkBlue z-50">
                      {dropdownLinks.map((item) => (
                        <Link
                          key={item.href}
                          to={item.href}
                          className="block px-4 py-2 hover:bg-gray-200"
                        >
                          {item.label}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              ) : (
                <Link
                  key={link.href}
                  to={link.href}
                  className="hover:text-accent"
                >
                  {link.label}
                </Link>
              )}
            </div>
          ))}
        </nav>

        {isOpen && (
          <nav
            className="absolute top-0 left-0 w-full h-full bg-primary text-neutralWhite flex flex-col items-center space-y-4 py-4 z-50"
            aria-label="Mobile Navigation"
          >
            <button
              onClick={() => setIsOpen(false)}
              className="self-end mr-6 text-neutralWhite hover:text-accent"
              aria-label="Close Mobile Menu"
            >
              <svg
                className="w-8 h-8"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            {navLinks.map((link) => (
              <div key={link.href} className="relative">
                <Link
                  to={link.href}
                  className="hover:text-accent text-xl py-2 block"
                  onClick={() => {
                    closeMenu();
                    if (link.hasDropdown) {
                      setDropdownOpen((prev) => !prev);
                    }
                  }}
                >
                  {link.label}
                </Link>
                {link.hasDropdown && getInvolvedOpen && (
                  <div className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg text-darkBlue">
                    {dropdownLinks.map((item) => (
                      <Link
                        key={item.href}
                        to={item.href}
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        {item.label}
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            ))}
            <div className="relative">
              <button
                className="bg-accent text-darkBlue py-2 px-4 rounded-full flex items-center text-xl"
                onClick={() => setGetInvolvedOpen((prev) => !prev)}
              >
                Get Involved <FaChevronDown className="ml-1" />
              </button>
              {getInvolvedOpen && (
                <div className="absolute left-0 mt-2 w-48 bg-white shadow-lg rounded-lg text-darkBlue">
                  {getInvolvedLinks.map((item) => (
                    <a
                      key={item.href}
                      href={item.href}
                      download={item.download}
                      target={item.download ? "_self" : "_blank"}
                      rel="noopener noreferrer"
                      className="block px-4 py-2 hover:bg-gray-200 text-lg"
                      onClick={closeMenu}
                    >
                      {item.label}
                    </a>
                  ))}
                </div>
              )}
            </div>
          </nav>
        )}
      </div>
    </header>
  );
}

export default Header;
